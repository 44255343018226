<template>
    <div class="">
        <div class="zxw-nav" style="padding-left: 0px;padding-bottom: 10px">
            <div class="wrap pr">
                <div class="uf uf-pc">
                    <li class="" @click="$router.push({ name: 'serve' })">首页</li>
                    <li class="pr" @click="$router.push({ name: 'index' })">
                        <i class="hot iconfont icon-hot"></i
                        ><span class="fwb">自助式服务</span>
                    </li>
                    <li class="pr" @click="$router.push({ name: 'tech-analysis' })">
                        <i class="hot iconfont icon-hot"></i
                        ><span class="fwb">推荐式服务</span>
                    </li>
                    <li class="pr" @click="$router.push({ name: 'quality-doctor' })">
                        <i class="hot iconfont icon-hot"></i
                        ><span class="fwb">一站式服务</span>
                    </li>
                    <li style="list-style-type: none" @click="toStandardbzfw">
                        标准服务
                    </li>
                    <li style="list-style-type: none" @click="toStandardqyxycx">
                        企业信用查询
                    </li>
                    <li style="list-style-type: none" @click="toStandardzcfw">
                        政策服务
                    </li>
                    <li style="list-style-type: none" @click="toStandardzwqyml">
                        小微企业名录
                    </li>
                    <li style="list-style-type: none" @click="toStandardrzrkfw">
                        认证认可服务
                    </li>
                    <li style="list-style-type: none" @click="toStandardsbjs">
                        商标工作台
                    </li>
                    <li style="list-style-type: none" @click="toSCRM">SCRM</li>
                </div>
            </div>
        </div>
        <div style="width: 85%; margin: 0 auto;">
<!--            <div class="uf uf-ac uf-pc fwrap-w mt-4 server-img">-->
<!--                <div class="big-img tac" v-for="item in serviceList" :key="item.type" @click="serviceInfo(item)">-->
<!--                    <div class="tit f26 fwb">{{item.name}}</div>-->
<!--                    <div class="dec mt-4">专业检测</div>-->
<!--                    <img class="mt-4" src="../../../assets/img/index/jiadian.jpg" width="240" />-->
<!--                </div>-->
<!--                <i></i><i></i><i></i><i></i><i></i><i></i>-->
<!--            </div>-->
            <div class="uf uf-ac uf-pc fwrap-w mt-4 server-img">
                <div class="big-el-img uf uf-ac uf-pa" v-for="item in serviceList" :key="item.type" @click="serviceInfo(item)">
                    <el-image class="" style="width: 150px; height: 150px" :src="item.src"></el-image>
                    <div class="tit f18 fwb">{{item.name}}</div>
                </div>
                <i></i><i></i><i></i><i></i><i></i><i></i>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'service-list',
    data () {
      return{
        serviceList:[
          {
            name: '技术转化服务',
            type: 'conversion',
            src: require('../../../assets/img/index/conversion.jpg')
          },
          //{
          //  name: '专家服务',
          //  type: 'expert',
          //  src: require('../../../assets/img/index/expert.jpg')
          //},
          {
            name: '仪器设备',
            type: 'equipment',
            src: require('../../../assets/img/index/equipment.jpg')
          }, {
            name: '检验检测认证服务',
            type: 'test',
            src: require('../../../assets/img/index/test.jpg')
          }, {
            name: '软件服务',
            type: 'software',
            src: require('../../../assets/img/index/software.jpg')
          },
          {
            name: '科技咨询',
            type: 'consult',
            src: require('../../../assets/img/index/consult.jpg')
          },
          {
            name: '研究研发',
            type: 'research',
            src: require('../../../assets/img/index/research.jpg')
          },
          //{
          //  name: '服务机构',
          //  type: 'mechanism',
          //  src: require('../../../assets/img/index/mechanism.jpg')
          //},
        ],

      }
    },
    methods:{
      // 跳转服务详情
      serviceInfo (item) {
        this.$router.push({name:'service-info', query:{type:item.type}})
      },
      // 顶部 tab 标签 切换时跳转的网址
      toStandardbzfw() {
        window.open('http://std.samr.gov.cn/')
      },
      toStandardzcfw() {
        window.open('http://gjzwfw.www.gov.cn/col/col644/index.html')
      },
      toStandardqyxycx() {
        window.open('http://www.gsxt.gov.cn/index.html')
      },
      toStandardzwqyml() {
        window.open('http://xwqy.gsxt.gov.cn/')
      },
      toStandardrzrkfw() {
        window.open('http://cx.cnca.cn/CertECloud/index/index/page')
      },
      toStandardsbjs() {
        window.open('https://tm.gongsibao.com/tm/tmdashboard')
      },
      toSCRM() {
        window.open('https://scrm.gongsibao.com/home.html')
      }
    },
  }
</script>

<style scoped>
    .zxw-nav {
        /*background: linear-gradient(90deg, #35bce2, #409eff);*/
        background: white;
        line-height: 2.4rem;
        width: 100%;
        outline: none;
    }

    .zxw-nav .wrap {
        width: 1300px;
        margin: 0 auto;
    }

    .zxw-nav li {
        width: 7rem;
        color: #404040;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
    }

    .zxw-nav li:hover,
    .zxw-nav li.on {
        background: #69c2ff;
        font-size: 15px;
        color: white;
    }
    .big-img{
        height: 350px;
        transition: all .4s ease;
        padding: 25px;
        cursor: pointer;
        /*color: #e9e9e9;*/
        background-color: #d7d6eb;
        border-radius: 10px;
        margin: 15px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
    }
    .big-img:hover{
        transform: scale(1.1,1.1);
    }
    .server-img i{
        width: 375px;
    }

    .big-el-img{
        height: 200px;
        width: 345px;
        transition: all .4s ease;
        padding: 25px;
        cursor: pointer;
        /*color: #e9e9e9;*/
        background-color: #fff;
        border-radius: 10px;
        margin: 15px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
    }
    .big-el-img:hover{
        transform: scale(1.1,1.1);
    }
</style>
